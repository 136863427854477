interface AuthStore {
  phone: string;
  company: string;
  email: string;
  password: string;
  name: string;
}
export const name = 'register';
export default {
  state: () =>
    ({
      phone: '',
      company: '',
      email: '',
      password: '',
      name: '',
    } as AuthStore),
  mutations: {
    setPhone(state: AuthStore, data: string) {
      // `state` is the local module state
      state.phone = data;
    },
    setCompany(state: AuthStore, data: string) {
      state.company = data;
    },
    setEmail(state: AuthStore, data: string) {
      state.email = data;
    },
    setPassword(state: AuthStore, data: string) {
      state.password = data;
    },
    setName(state: AuthStore, data: string) {
      state.name = data;
    },
  },
  getters: {
    getPhone(state: AuthStore) {
      return state.phone;
    },
    getCompany(state: AuthStore) {
      return state.company;
    },
    getEmail(state: AuthStore) {
      return state.email;
    },
    getPassword(state: AuthStore) {
      return state.password;
    },
    getName(state: AuthStore) {
      return state.name;
    },
  },
};
