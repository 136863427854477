import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.DASHBOARD.path,
    name: ConstRouter.DASHBOARD.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./Dashboard.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_PROFILE.path,
    name: ConstRouter.DASHBOARD_PROFILE.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./User/Profile.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_MORE_INFO.path,
    name: ConstRouter.DASHBOARD_MORE_INFO.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./DashboardMoreInfo.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_SALARY_ADVANCE.path,
    name: ConstRouter.DASHBOARD_SALARY_ADVANCE.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./SalaryAdvance/List/SalaryAdvanceList.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_SALARY_ADVANCE_GEN.path,
    name: ConstRouter.DASHBOARD_SALARY_ADVANCE_GEN.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./SalaryAdvance/List/SalaryAdvanceListGen.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_SALARY_ADVANCE_ITEM.path,
    name: ConstRouter.DASHBOARD_SALARY_ADVANCE_ITEM.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./SalaryAdvance/Item/SalaryAdvanceItemDetail.vue'),
  },
  {
    path: ConstRouter.DASHBOARD_SALARY_ADVANCE_ITEM_LEADGEN.path,
    name: ConstRouter.DASHBOARD_SALARY_ADVANCE_ITEM_LEADGEN.name,
    meta: {
      middleware: [auth],
    },
    component: () =>
      import('./SalaryAdvance/Item/SalaryAdvanceItemDetailLeadgen.vue'),
  },

  {
    path: ConstRouter.DASHBOARD_REGISTER_PROGRESS.path,
    name: ConstRouter.DASHBOARD_REGISTER_PROGRESS.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./DashboardRegisterProgress.vue'),
  },
];
