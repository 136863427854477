interface AuthStore {
  count: number;
}

export default {
  state: () =>
    ({
      count: 1,
    } as AuthStore),

  mutations: {
    increment(state: AuthStore) {
      // `state` is the local module state
      state.count++;
    },
  },

  getters: {
    doubleCount(state: AuthStore) {
      return state.count * 2;
    },
  },
};

export const name = 'auth';
