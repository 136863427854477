import ConstRouter from '@/constants/constRouter';

export default [
  // {
  //   path: ConstRouter.BORROWER_SALARY.path,
  //   name: ConstRouter.BORROWER_SALARY.name,
  //   meta: {
  //     middleware: [requireName],
  //   },
  //   component: () => import('./BorrowerExigency.vue'),
  // },
  {
    path: ConstRouter.BORROWER_MONEY.path,
    name: ConstRouter.BORROWER_MONEY.name,
    component: () => import('./BorrowerExigency.vue'),
  },
  {
    path: ConstRouter.BORROWER_LOAN.path,
    name: ConstRouter.BORROWER_LOAN.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerLoan.vue'),
  },
  {
    path: ConstRouter.BORROWER_CHOOSE_LOAN.path,
    name: ConstRouter.BORROWER_CHOOSE_LOAN.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerChooseLoan.vue'),
  },
  {
    path: ConstRouter.BORROWER_ADDRESS.path,
    name: ConstRouter.BORROWER_ADDRESS.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerAddress.vue'),
  },
  {
    path: ConstRouter.BORROWER_COMPANY_ADDRESS.path,
    name: ConstRouter.BORROWER_COMPANY_ADDRESS.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerCompanyAddress.vue'),
  },
  {
    path: ConstRouter.BORROWER_HOUSE_HOLD.path,
    name: ConstRouter.BORROWER_HOUSE_HOLD.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerHouseHold.vue'),
  },
  {
    path: ConstRouter.BORROWER_LOAN_SUCCESS.path,
    name: ConstRouter.BORROWER_LOAN_SUCCESS.name,
    // meta: {
    //   middleware: [requireName],
    // },
    component: () => import('./BorrowerLoanSuccess.vue'),
  },
];
