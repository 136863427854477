export default {
  //=======================INTRO=======================
  INTRO: {
    path: '/intro',
    name: 'intro',
  },
  INTRO_ACCOUNT: {
    path: '/intro/account',
    name: 'intro.account',
  },
  INTRO_INPUT_NAME: {
    path: '/intro/input-name',
    name: 'intro.input-name',
  },
  INTRO_CHOOSE_NEED: {
    path: '/intro/choose-need',
    name: 'intro.choose-need',
  },

  //=======================UI=======================
  UI: {
    path: '/ui',
    name: 'ui',
  },
  UI_BUTTON: {
    path: '/ui/button',
    name: 'ui.button',
  },

  //=======================LOGIN=======================
  LOGIN: {
    path: '/login',
    name: 'login',
  },

  //=======================FORGOT_PASS=======================
  FORGOT_PASS: {
    path: '/forgotPass',
    name: 'forgotPass',
  },
  FORGOT_PASS_OTP: {
    path: '/forgotPass/otp',
    name: 'forgotPass.otp',
  },

  FORGOT_PASS_ENTER_NEW_PASS: {
    path: '/forgotPass/enterNewPass',
    name: 'forgotPass.enterNewPass',
  },

  //=======================DASHBOARD=======================
  DASHBOARD: {
    path: '/dashboard',
    name: 'dashboard',
  },

  DASHBOARD_PROFILE: {
    path: '/dashboard/profile',
    name: 'dashboard.profile',
  },
  DASHBOARD_MORE_INFO: {
    path: '/dashboard/more-info',
    name: 'dashboard.more-info',
  },

  DASHBOARD_SALARY_ADVANCE: {
    path: '/dashboard/salary-advance',
    name: 'dashboard.salary-advance',
  },

  DASHBOARD_SALARY_ADVANCE_GEN: {
    path: '/dashboard/salary-advance-gen',
    name: 'dashboard.salary-advance-gen',
  },

  DASHBOARD_SALARY_ADVANCE_ITEM: {
    path: '/dashboard/salary-advance/:id',
    name: 'dashboard.salary-advance.id',
  },

  DASHBOARD_SALARY_ADVANCE_ITEM_LEADGEN: {
    path: '/dashboard/salary-advance/lead/:id',
    name: 'dashboard.salary-advance.lead.id',
  },

  DASHBOARD_REGISTER_PROGRESS: {
    path: '/dashboard/register-progress',
    name: 'dashboard.register-progress',
  },

  //=======================LENDER=======================
  LENDER: {
    path: '/lender',
    name: 'lender',
  },
  LENDER_INVESTS: {
    path: '/lender/invests',
    name: 'lender.invests',
  },
  LENDER_SHOW: {
    path: '/lender/show/:id',
    name: 'lender.show',
  },
  LENDER_NOTI: {
    path: '/lender/noti',
    name: 'lender.noti',
  },

  //=======================BORROWER=======================
  BORROWER: {
    path: '/borrower',
    name: 'borrower',
  },
  BORROWER_EXIGENCY: {
    path: '/borrower/exigency',
    name: 'borrower.exigency',
  },
  BORROWER_SALARY: {
    path: '/borrower/salary',
    name: 'borrower.salary',
  },
  BORROWER_MONEY: {
    path: '/borrower/money',
    name: 'borrower.money',
  },
  BORROWER_LOAN: {
    path: '/borrower/loan/:type',
    name: 'borrower.loan',
  },
  BORROWER_CHOOSE_LOAN: {
    path: '/borrower/choose-loan',
    name: 'borrower.choose-loan',
  },
  BORROWER_ADDRESS: {
    path: '/borrower/address',
    name: 'borrower.address',
  },
  BORROWER_COMPANY_ADDRESS: {
    path: '/borrower/company-address',
    name: 'borrower.company-address',
  },
  BORROWER_HOUSE_HOLD: {
    path: '/borrower/house-hold',
    name: 'borrower.house-hold',
  },
  BORROWER_LOAN_SUCCESS: {
    path: '/borrower/loan-success',
    name: 'borrower.loan-success',
  },

  //=======================REQUIRE_MOBILE=======================
  REQUIRE_MOBILE: {
    path: '/require-mobile',
    name: 'require.mobile',
  },

  //=======================REGISTER=======================
  REGISTER: {
    path: '/register',
    name: 'register',
  },
  REGISTER_PHONE: {
    path: '/register/phone',
    name: 'register.phone',
  },
  REGISTER_SUCCESS: {
    path: '/register/success',
    name: 'register.success',
  },
  REGISTER_COMPANY: {
    path: '/register/company',
    name: 'register.company',
  },
  CONFIRM_OTP: {
    path: '/register/otp',
    name: 'register.otp',
  },

  //=======================EKYC=======================
  EKYC: {
    path: '/ekyc',
    name: 'ekyc',
  },
  EKYC_VIEW: {
    path: '/ekyc/view',
    name: 'ekyc.view',
  },
  EKYC_INTRO: {
    path: '/ekyc/intro',
    name: 'ekyc.intro',
  },
  EKYC_INTRO_CONFIRM: {
    path: '/ekyc/confirm',
    name: 'ekyc.confirm',
  },
};
