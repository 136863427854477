interface AuthStore {
  name: string;
}

export const name = 'intro';
export default {
  namespaced: true,
  state: () =>
    ({
      name: '',
    } as AuthStore),
  mutations: {
    setName(state: AuthStore, data: string) {
      // `state` is the local module state
      state.name = data;
    },
  },
  getters: {
    getName(state: AuthStore) {
      return state.name;
    },
  },
};
