import axios from 'axios';
import { destroyToken, getAuthToken } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';

const Axios = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: false,
});

Axios.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers.common['Authorization'] = token;
  }
  return config;
});

Axios.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    if (error.response?.status) {
      switch (+error.response.status) {
        case 400:
          // do something
          break;
        case 401:
        case 403: {
          destroyToken();
          document.location.href = constRouter.LOGIN.path;
          break;
        }
        case 404:
          // Vue.$router.push({ name: "404" });
          break;
        case 502:
          setTimeout(() => {
            // Vue.$router.replace({
            //   path: "/login",
            //   query: {
            //     redirect: router.currentRoute.fullPath
            //   }
            // });
          }, 1000);
          break;
        default:
          break;
      }
    }
    return Promise.reject(error.response);
  },
);

export default Axios;
