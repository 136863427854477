import ConstRouter from '@/constants/constRouter';

export default [
  {
    path: ConstRouter.EKYC_INTRO.path,
    name: ConstRouter.EKYC_INTRO.name,
    component: () => import('./EkycIntro.vue'),
  },
  {
    path: ConstRouter.EKYC.path,
    name: ConstRouter.EKYC.name,
    component: () => import('./Ekyc.vue'),
  },
  {
    path: ConstRouter.EKYC_VIEW.path,
    name: ConstRouter.EKYC_VIEW.name,
    component: () => import('./EkycView.vue'),
  },
  {
    path: ConstRouter.EKYC_INTRO_CONFIRM.path,
    name: ConstRouter.EKYC_INTRO_CONFIRM.name,
    component: () => import('./EkycConfirm.vue'),
  },
];
