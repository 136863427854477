interface ExigencyStore {
  isSalary: boolean;
  contractId: string;
  amount: number | string;
  paidMoney: number;
  realMoney: number;
  monthlyPayment: number;
  dateSalary: string;
  term: string;
  email: string;
  monthlyIncome: number | string;
  address: {
    province: string;
    district: string;
    ward: string;
    street: string;
    houseNumber: string;
  };
  companyAddress: {
    province: string;
    district: string;
    ward: string;
    street: string;
    houseNumber: string;
    telephone: string;
    dateSalary: string;
    // moneyInsurance: number;
  };
}
export const name = 'borrower';

export default {
  state: () =>
    ({
      isSalary: false,
      contractId: '',
      amount: '',
      paidMoney: 0,
      realMoney: 0,
      monthlyPayment: 0,
      dateSalary: '',
      term: '',
      email: '',
      monthlyIncome: '',
      address: {
        province: '',
        district: '',
        ward: '',
        street: '',
        houseNumber: '',
      },
      companyAddress: {
        province: '',
        district: '',
        ward: '',
        street: '',
        houseNumber: '',
        telephone: '',
        dateSalary: '',
        // moneyInsurance: 0,
      },
    } as ExigencyStore),
  mutations: {
    setExigency(state: ExigencyStore, data: ExigencyStore) {
      // `state` is the local module state
      state.amount = data.amount;
      state.dateSalary = data.dateSalary;
      state.term = data.term;
      state.monthlyIncome = data.monthlyIncome;
      state.paidMoney = data.paidMoney;
      state.realMoney = data.realMoney;
      state.monthlyPayment = data.monthlyPayment;
    },
    setSalary(state: ExigencyStore, value: boolean) {
      state.isSalary = value;
    },
    setContractId(state: ExigencyStore, value: string) {
      state.contractId = value;
    },
  },
  getters: {
    getExigency(state: ExigencyStore) {
      return state;
    },
  },
};
