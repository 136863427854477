interface Ekycstore {
  ocrId: string;
  nicNumber: string;
  fullName: string;
  birthday: string;
  address: string;
  gender: string;
  nicDateProvide: string;
  cardType: string;
  statusMatch: string;
  per: string;
}
export const name = 'ekyc';

export default {
  state: () =>
    ({
      nicNumber: '',
      fullName: '',
      birthday: '',
      address: '',
      statusMatch: '',
      per: '',
      ocrId: '',
      nicDateProvide: '',
      cardType: '',
      gender: '',
    } as Ekycstore),
  mutations: {
    setEkyc(state: Ekycstore, data: Ekycstore) {
      // `state` is the local module state
      state.ocrId = data.ocrId;
      state.nicNumber = data.nicNumber;
      state.fullName = data.fullName;
      state.birthday = data.birthday;
      state.address = data.address;
      state.gender = data.gender;
      state.nicDateProvide = data.nicDateProvide;
      state.cardType = data.cardType;
    },
    setFaceMatch(state: Ekycstore, data: Ekycstore) {
      state.statusMatch = data.statusMatch;
      state.per = data.per;
    },
  },
  getters: {
    getEkyc(state: Ekycstore) {
      return state;
    },
  },
};
