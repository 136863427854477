import Axios from '../services/axios';

const PATH = 'customers';
export function signUp(data: object) {
  return Axios.post(`${PATH}/sign-up`, data);
}

export function confirmOTP(data: object) {
  return Axios.post(`${PATH}/active`, data);
}

export function login(data: object) {
  return Axios.post(`${PATH}/sign-in`, data);
}

export function profile() {
  return Axios.get(`${PATH}/profile`);
}
