import { createApp } from 'vue';
import App from './App.vue';
import config from './plugins/config';
import eventBus from './plugins/eventBus';
import localization from './mixins/localization';
import router from './plugins/router';
import store from './plugins/store';
import vi from './constants/vi.json';
import './registerServiceWorker';
import './assets/scss/main.scss';
import './services/sematext';

(window as any).translations = vi;

const app = createApp(App)
  .use(store)
  .use(router)
  .use(config)
  .use(eventBus);

app.mixin(localization);

app.mount('#app');
