import ConstRouter from '@/constants/constRouter';
// import { log } from '@/middleware/log';

export default [
  {
    path: ConstRouter.LOGIN.path,
    name: ConstRouter.LOGIN.name,
    component: () => import('./Login.vue'),
  },
];
