import ConstRouter from '@/constants/constRouter';

export default [
  {
    path: ConstRouter.LENDER.path,
    name: ConstRouter.LENDER.name,
    component: () => import('./LenderIntro.vue'),
  },
  {
    path: ConstRouter.LENDER_INVESTS.path,
    name: ConstRouter.LENDER_INVESTS.name,
    component: () => import('./LenderInvests.vue'),
  },
  {
    path: ConstRouter.LENDER_SHOW.path,
    name: ConstRouter.LENDER_SHOW.name,
    component: () => import('./LenderShow.vue'),
  },
  {
    path: ConstRouter.LENDER_NOTI.path,
    name: ConstRouter.LENDER_NOTI.name,
    component: () => import('./LenderNoti.vue'),
  },
];
