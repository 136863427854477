/* eslint-disable */
function setupSemaText() {
  (function(e, r, n, t, s) {
    var a = [];
    e[s] = function() {
      a.push(arguments);
    };
    e[s].queue = a;
    var o = [];
    let i = [];
    var c = true;
    var p = void 0;
    if (
      window.PerformanceObserver &&
      window.PerformanceObserver.supportedEntryTypes &&
      (PerformanceObserver.supportedEntryTypes.indexOf('longtask') >= 0 ||
        PerformanceObserver.supportedEntryTypes.indexOf('element') >= 0)
    ) {
      p = new PerformanceObserver(function(e) {
        e.getEntries().forEach(function(e) {
          switch (e.entryType) {
            case 'element':
              i.push(e);
              break;
            case 'longtask':
              o.push(e);
              break;
            default:
              break;
          }
        });
      });
      p.observe({ entryTypes: ['longtask', 'element'] });
    }
    e[s + 'lt'] = {
      longTasks: o,
      timingElements: i,
      inPageLoad: c,
      observer: p,
    };
    if (t) {
      var u = r.createElement(n);
      u.async = 1;
      u.src = t;
      var f = r.getElementsByTagName(n)[0];
      f.parentNode.insertBefore(u, f);
    }
  })(window, document, 'script', '//cdn.sematext.com/experience.js', 'strum');

  strum('config', {
    token: '61c682c4-a54a-4a01-a016-581d505156e8',
    receiverUrl: 'https://rum-receiver.sematext.com',
  });
}

process.env.NODE_ENV === 'production' && setupSemaText();
