import ConstRouter from '@/constants/constRouter';
import store from '@/plugins/store';
import { getAuthToken } from '@/helpers/localStorage';

export function requireName() {
  const name = store.getters['intro/getName'];
  const token = getAuthToken();
  if (token) {
    return null;
  }
  if (!name) {
    return ConstRouter.INTRO_INPUT_NAME.name;
  } else {
    return null;
  }
}

export function requireData() {
  const token = getAuthToken();
  if (token) {
    return null;
  }
  return null;
}

export function auth() {
  if (!getAuthToken()) {
    return ConstRouter.LOGIN.name;
  }
  return null;
}

export function checkNotLogin() {
  if (getAuthToken()) {
    return ConstRouter.DASHBOARD.name;
  }
  null;
}
