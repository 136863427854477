import { createStore } from 'vuex';
import { profile } from '@/apis/ApiAuth';

const modules: any = {};

// Auto-load all store.ts in folder views
const requireComponent = require.context('../views', true, /store.ts$/);
requireComponent.keys().forEach((fileName: string) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  modules[componentConfig.name] = componentConfig.default;
});

const getDefaultState = () => {
  return {
    user: {
      nicBackImageUrl: '',
      nicFrontImageUrl: '',
      facePhotoImageUrl: '',
    },
    loading: false,
    campaign: {
      utmSource: undefined,
      utmContent: undefined,
      clickId: undefined,
      utmCampaign: undefined,
    },
  };
};

const store = createStore({
  state: getDefaultState(),
  mutations: {
    SET_USER_INFO(state, data) {
      state.user = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
    },
    SET_CAMPAIGN(state, data) {
      state.campaign = data;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      commit('SET_LOADING', true);
      try {
        const resp = await profile();
        commit('SET_USER_INFO', resp.data.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      commit('SET_LOADING', false);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getLoading(state) {
      return state.loading;
    },
    isDoneEKYC(state) {
      if (state.user) {
        if (!state.user.nicFrontImageUrl) {
          return false;
        }
        if (!state.user.nicBackImageUrl) {
          return false;
        }
        return state.user.facePhotoImageUrl;
      }
      return false;
    },
    getCampaign(state) {
      return state.campaign;
    },
  },
  modules,
});

export default store;
