import ConstRouter from '@/constants/constRouter';

export default [
  {
    path: ConstRouter.REGISTER_PHONE.path,
    name: ConstRouter.REGISTER_PHONE.name,
    component: () => import('./RegisterPhone.vue'),
  },
  {
    path: ConstRouter.REGISTER_SUCCESS.path,
    name: ConstRouter.REGISTER_SUCCESS.name,
    component: () => import('./RegisterSuccess.vue'),
  },
  {
    path: ConstRouter.CONFIRM_OTP.path,
    name: ConstRouter.CONFIRM_OTP.name,
    component: () => import('./ConfirmOTP.vue'),
  },
  {
    path: ConstRouter.REGISTER_COMPANY.path,
    name: ConstRouter.REGISTER_COMPANY.name,
    component: () => import('./RegisterCompany.vue'),
  },
];
