// TOKEN
export function destroyToken() {
  localStorage.removeItem('accessToken');
}

export function setAuthToken(token: string) {
  localStorage.setItem('accessToken', token);
}

export function getAuthToken() {
  if (localStorage.accessToken) {
    return localStorage.accessToken;
  }
  return null;
}

// contract is approve
export function setApproval(data: string) {
  localStorage.setItem('isApprove', data);
}

export function getApproval() {
  if (localStorage.isApprove) {
    return localStorage.isApprove;
  }
  return null;
}

// set contract id
export function setContractId(data: string) {
  localStorage.setItem('contractId', data);
}

export function getContractId() {
  if (localStorage.contractId) {
    return localStorage.contractId;
  }
  return null;
}
